@tailwind base;
@tailwind components;
@tailwind utilities;


.MuiDataGrid-cell:focus {
    outline: none !important;
}

.cover-image{
    background-image: url(./assets/cover-page.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 600px;
}

.MuiDataGrid-columnHeader, 
.css-1iyq7zh-MuiDataGrid-columnHeaders{
    background-color: rgb(12,74,110) !important;
    color: white !important;
}

.MuiDataGrid-columnHeader:focus{
    outline: none !important;
}

.MuiSvgIcon-root{
    color:white !important;
}

.MuiTablePagination-actions .MuiSvgIcon-root{
    color: rgb(12,74,110) !important;
}

.MuiDataGrid-columnSeparator{
    display: none !important;
}